import React from "react";
import Header from "../../Components/Header/Header"
import JobDetailsPage from "../../Components/JobDetails/JobDetails"
import SlidePage from "../../Components/JobDetails/Slide/Slide"
import OfficerPage from "../../Components/JobDetails/Officer/Officer"
import AboutContent from "../../Components/JobDetails/AllContent/Content"
import  SearchPage from "../../Components/JobDetails/SearchProfile/SearchProfile"


import FooterPage from "../../Components/Footer/Footer"

const JobDetails = (props) => {
    return (
        <div className="wrapper slide">
            <div className="jobdetails-header"><Header nobanner={true} /></div>
            <JobDetailsPage />
            <SlidePage />
            <OfficerPage />
            <AboutContent />
            <SearchPage />
            <FooterPage />
        </div>
    )
};
export default JobDetails